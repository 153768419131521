<template>
	<div>
		<div class="w-full sm:w-2/3 md:w-3/5 lg:w-4/5 xl:w-1/2 mx-auto">

			<h1 class="text-3xl font-bold mb-10">Add a new beneficiary</h1>

			<form @submit.prevent="submit" class="card border border-blue-200 pt-16 pb-10 px-10 md:px-16">

				<template v-if="getFormError(form)">
					<div class="alert alert-red-soft mb-16">
						<div class="alert-icon">!</div>
						<span>{{ getFormError(form) }}</span>
					</div>
				</template>

				<form-group
					left-icon="person-outline"
					name="name"
					:form="form"
					v-model="form.data.name.value"
				>
					Beneficiary Name
				</form-group>

				<form-group
					left-icon="locate-outline"
					name="account_number"
					:form="form"
					v-model="form.data.account_number.value"
				>
					Beneficiary Account Number
				</form-group>

				<form-group
					type="select"
					:options="banks"
					left-icon="business-outline"
					name="bank_id"
					:form="form"
					v-model="form.data.bank_id.value"
				>
					Beneficiary Bank
				</form-group>

				<form-group
					type="textarea"
					left-icon="reader-outline"
					name="description"
					:form="form"
					v-model="form.data.description.value"
				>
					Beneficiary Description
				</form-group>

				<div class="text-center">
					<button type="submit" class="btn btn-blue btn-md" :disabled="form.loading">
						<span v-if="form.loading">Loading</span>
						<span v-else>Submit</span>
					</button>
				</div>

			</form>

		</div>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="successModal">
			<img :src="assets.icons.lg.checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

			<div class="text-lg font-bold mb-4">
				Success!
			</div>
			<div class="text-sm mb-10">
				Beneficiary Addedd Successfully.
			</div>

			<button type="button" class="btn btn-blue" @click.prevent="$refs.successModal.close">
				Okay
			</button>
		</modal>

	</div>
</template>
<script>
	export default {
		data() {
			return {
				form: this.$options.basicForm([
					'name', 'bank_id', 'description', {name: 'account_number', rules: 'required|number|length:10'},
				]),
			}
		},
		computed: {
			banks() {
				return this.$store.state.banks.map(bank => ({ title: bank.name, value: bank.code }));
			}
		},
		methods: {
			async submit() {
				if (!this.validateForm(this.form)) {
					return false;
				}

				this.form.loading = true;
				await this.sendRequest(`corporate.beneficiaries.new`, {
					data: {
						...this.getFormData(),
						company_id: this.user.company_id,
					},
					success: () => {
						this.$refs.successModal.open();
						this.form = this.resetForm(this.form);
					},
					error: error => {
						this.form.error = error;
						this.mapFormErrors(this.form, error?.response?.data?.errors);
						console.log(error);
					}
				})
				this.form.loading = false;
			},
		}
	}
</script>